import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const Facebook = 'facebook';
const Instagram = 'instagram'
const Twitter = 'twitter';
const WhatsApp = 'whatsapp';
const Linkedin = 'linkedin';
const Email = 'email';
const Copy = 'copy';
const Browser = "browser";
const Tiktok = "tiktok";
const Message = "message";
const Share = "share";
const Like = "like";
const Pinterest = "pinterest";
const ShareOrange = "shareOrange";
const CopyLockIcon = "CopyLockIcon";
const Youtube = "Youtube"

const IconShare = props => {
    const { className, rootClassName, type } = props;
    const classes = classNames(rootClassName || css.root, className);

    switch (type) {
        case Browser:
            return (
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8077 11.5059C12.8775 10.9125 12.9299 10.3192 12.9299 9.70785C12.9299 9.09651 12.8775 8.50316 12.8077 7.90981H15.7595C15.8993 8.48518 15.9866 9.08752 15.9866 9.70785C15.9866 10.3282 15.8993 10.9305 15.7595 11.5059M11.2619 16.5044C11.7859 15.5065 12.1876 14.4277 12.4671 13.3039H15.0434C14.1973 14.8037 12.8549 15.9398 11.2619 16.5044ZM11.0435 11.5059H6.95633C6.869 10.9125 6.8166 10.3192 6.8166 9.70785C6.8166 9.09651 6.869 8.49417 6.95633 7.90981H11.0435C11.1221 8.49417 11.1833 9.09651 11.1833 9.70785C11.1833 10.3192 11.1221 10.9125 11.0435 11.5059ZM8.99994 16.864C8.27507 15.7852 7.68994 14.5895 7.33187 13.3039H10.668C10.3099 14.5895 9.7248 15.7852 8.99994 16.864ZM5.5066 6.11177H2.95647C3.79387 4.6079 5.13526 3.46999 6.72927 2.91126C6.20527 3.90917 5.81227 4.98799 5.5066 6.11177ZM2.95647 13.3039H5.5066C5.81227 14.4277 6.20527 15.5065 6.72927 16.5044C5.1386 15.9396 3.7991 14.8032 2.95647 13.3039ZM2.24034 11.5059C2.1006 10.9305 2.01327 10.3282 2.01327 9.70785C2.01327 9.08752 2.1006 8.48518 2.24034 7.90981H5.1922C5.12233 8.50316 5.06993 9.09651 5.06993 9.70785C5.06993 10.3192 5.12233 10.9125 5.1922 11.5059M8.99994 2.54266C9.7248 3.62148 10.3099 4.82617 10.668 6.11177H7.33187C7.68994 4.82617 8.27507 3.62148 8.99994 2.54266ZM15.0434 6.11177H12.4671C12.1937 4.9983 11.789 3.92346 11.2619 2.91126C12.8688 3.47764 14.205 4.6194 15.0434 6.11177ZM8.99994 0.717651C4.1704 0.717651 0.266602 4.76324 0.266602 9.70785C0.266602 12.0922 1.18672 14.3789 2.82454 16.0649C3.6355 16.8997 4.59826 17.5619 5.65783 18.0137C6.71741 18.4655 7.85306 18.698 8.99994 18.698C11.3162 18.698 13.5375 17.7509 15.1753 16.0649C16.8132 14.3789 17.7333 12.0922 17.7333 9.70785C17.7333 8.52724 17.5074 7.35819 17.0685 6.26745C16.6296 5.17671 15.9863 4.18564 15.1753 3.35082C14.3644 2.516 13.4016 1.85379 12.342 1.40199C11.2825 0.95019 10.1468 0.717651 8.99994 0.717651Z" fill="white" />
                </svg>
            );
        case Like:
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4998 4.29376L11.3795 3.1422C8.74984 0.439077 3.92796 1.37189 2.18734 4.77033C1.37015 6.36876 1.18577 8.67658 2.67796 11.6219C4.11546 14.4578 7.10609 17.8547 12.4998 21.5547C17.8936 17.8547 20.8826 14.4578 22.3217 11.6219C23.8139 8.67502 23.6311 6.36876 22.8123 4.77033C21.0717 1.37189 16.2498 0.437515 13.6201 3.14064L12.4998 4.29376ZM12.4998 23.4375C-11.458 7.60627 5.12327 -4.74999 12.2248 1.78595C12.3186 1.87189 12.4108 1.96095 12.4998 2.05314C12.588 1.96104 12.6797 1.87243 12.7748 1.78751C19.8748 -4.75311 36.4576 7.6047 12.4998 23.4375Z" fill="#939393" />
                </svg>
            );
        case Pinterest:
            return (
                <svg width="18px" height="18px" viewBox="0 0 0.337 0.337" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.169C0 0.076 0.076 0 0.169 0C0.262 0 0.337 0.076 0.337 0.169C0.337 0.262 0.262 0.337 0.169 0.337C0.145 0.337 0.123 0.333 0.103 0.324L0.146 0.226C0.157 0.239 0.173 0.247 0.191 0.247C0.235 0.247 0.27 0.212 0.27 0.169V0.158C0.27 0.108 0.23 0.068 0.18 0.068H0.158C0.108 0.068 0.068 0.108 0.068 0.158V0.169C0.068 0.189 0.075 0.207 0.088 0.221L0.104 0.206C0.095 0.196 0.09 0.183 0.09 0.169V0.158C0.09 0.12 0.12 0.09 0.158 0.09H0.18C0.217 0.09 0.247 0.12 0.247 0.158V0.169C0.247 0.2 0.222 0.225 0.191 0.225C0.176 0.225 0.162 0.215 0.158 0.201L0.157 0.2L0.179 0.151L0.158 0.142L0.083 0.314C0.033 0.285 0 0.231 0 0.169Z" fill="#000000" /></svg>
            );
        case Instagram:
            return (
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66442 5.88116C7.15513 5.88116 5.92344 7.15137 5.92344 8.70786C5.92344 10.2643 7.15513 11.5345 8.66442 11.5345C10.1737 11.5345 11.4054 10.2643 11.4054 8.70786C11.4054 7.15137 10.1737 5.88116 8.66442 5.88116ZM16.8853 8.70786C16.8853 7.53731 16.8956 6.37737 16.8319 5.20895C16.7681 3.85179 16.4679 2.64732 15.5056 1.6549C14.5412 0.660366 13.3753 0.352886 12.0593 0.287149C10.9242 0.221412 9.79947 0.232015 8.66648 0.232015C7.53142 0.232015 6.40665 0.221412 5.27366 0.287149C3.95766 0.352886 2.78971 0.662487 1.82738 1.6549C0.862996 2.64944 0.564839 3.85179 0.501096 5.20895C0.437352 6.37949 0.447633 7.53943 0.447633 8.70786C0.447633 9.87628 0.437352 11.0383 0.501096 12.2068C0.564839 13.5639 0.865053 14.7684 1.82738 15.7608C2.79176 16.7553 3.95766 17.0628 5.27366 17.1286C6.40871 17.1943 7.53348 17.1837 8.66648 17.1837C9.80153 17.1837 10.9263 17.1943 12.0593 17.1286C13.3753 17.0628 14.5432 16.7532 15.5056 15.7608C16.47 14.7663 16.7681 13.5639 16.8319 12.2068C16.8977 11.0383 16.8853 9.8784 16.8853 8.70786ZM8.66442 13.0571C6.33057 13.0571 4.44705 11.1147 4.44705 8.70786C4.44705 6.30103 6.33057 4.35861 8.66442 4.35861C10.9983 4.35861 12.8818 6.30103 12.8818 8.70786C12.8818 11.1147 10.9983 13.0571 8.66442 13.0571ZM13.0545 5.19622C12.5096 5.19622 12.0696 4.74243 12.0696 4.18048C12.0696 3.61853 12.5096 3.16474 13.0545 3.16474C13.5994 3.16474 14.0395 3.61853 14.0395 4.18048C14.0396 4.31392 14.0143 4.44607 13.9648 4.56939C13.9154 4.6927 13.8428 4.80474 13.7513 4.89909C13.6599 4.99345 13.5512 5.06826 13.4316 5.11925C13.3121 5.17023 13.1839 5.19639 13.0545 5.19622Z" fill="white" />
                </svg>
            );
        case Share:
            return (
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 2.59375V18.4062C20 19.5971 19.0406 20.5625 17.8571 20.5625H2.14286C0.959375 20.5625 0 19.5971 0 18.4062V2.59375C0 1.40287 0.959375 0.4375 2.14286 0.4375H17.8571C19.0406 0.4375 20 1.40287 20 2.59375ZM13.5714 12.2969C12.9213 12.2969 12.3293 12.5467 11.8847 12.9559L8.85085 11.1242C8.95446 10.7146 8.95446 10.2854 8.85085 9.87572L11.8847 8.04403C12.3293 8.45331 12.9213 8.70312 13.5714 8.70312C14.9521 8.70312 16.0714 7.57684 16.0714 6.1875C16.0714 4.79816 14.9521 3.67188 13.5714 3.67188C12.1907 3.67188 11.0714 4.79816 11.0714 6.1875C11.0714 6.40299 11.0984 6.61215 11.1491 6.81178L8.11527 8.64347C7.67067 8.23419 7.07866 7.98438 6.42857 7.98438C5.04786 7.98438 3.92857 9.11066 3.92857 10.5C3.92857 11.8893 5.04786 13.0156 6.42857 13.0156C7.07866 13.0156 7.67067 12.7658 8.11531 12.3566L11.1492 14.1883C11.0974 14.3923 11.0713 14.602 11.0715 14.8125C11.0715 16.2019 12.1908 17.3282 13.5715 17.3282C14.9522 17.3282 16.0715 16.2019 16.0715 14.8125C16.0714 13.4232 14.9521 12.2969 13.5714 12.2969Z" fill="#212121" />
                </svg>
            );
        case Message:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 6H4V4H16V6ZM16 9H4V7H16V9ZM16 12H4V10H16V12ZM20 2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H16L20 20V2Z" fill="#212121" />
                </svg>
            );
        case Tiktok:
            return (
                <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.3452 7.90792C14.7797 7.91152 13.2526 7.44301 11.9797 6.56859V12.6671C11.9792 13.7966 11.6195 14.8991 10.9485 15.8271C10.2775 16.7551 9.32733 17.4644 8.22493 17.8601C7.12254 18.2559 5.92051 18.3193 4.77958 18.0418C3.63865 17.7643 2.61319 17.1591 1.84033 16.3072C1.06747 15.4553 0.584044 14.3973 0.454693 13.2746C0.325342 12.152 0.556232 11.0182 1.11649 10.0248C1.67675 9.03147 2.53967 8.22594 3.58986 7.71594C4.64006 7.20594 5.82748 7.01577 6.99334 7.17086V10.2382C6.45984 10.0772 5.88696 10.082 5.3565 10.2521C4.82604 10.4221 4.36514 10.7487 4.03962 11.1851C3.71409 11.6215 3.54058 12.1455 3.54388 12.6821C3.54717 13.2188 3.7271 13.7408 4.05796 14.1735C4.38882 14.6062 4.8537 14.9275 5.3862 15.0916C5.91871 15.2556 6.49162 15.254 7.0231 15.0869C7.55459 14.9199 8.01748 14.5959 8.34567 14.1613C8.67386 13.7268 8.85056 13.2038 8.85055 12.6671V0.745117H11.9797C11.9775 0.998701 11.9996 1.25194 12.0458 1.50163C12.1546 2.05903 12.3806 2.58928 12.7102 3.05995C13.0398 3.53062 13.466 3.93181 13.9628 4.23899C14.6694 4.68741 15.498 4.92641 16.3452 4.92624V7.90792Z" fill="white" />
                </svg>
            );
        case Facebook:
            return (
                <svg width="81" height="81" viewBox="0 0 81 81" fill="none" >
                    <path d="M72 0H9C4.05 0 0 4.05 0 9V72C0 76.9545 4.05 81 9 81H40.5V49.5H31.5V38.3625H40.5V29.1375C40.5 19.3995 45.954 12.5595 57.447 12.5595L65.5605 12.5685V24.291H60.174C55.701 24.291 54 27.648 54 30.762V38.367H65.556L63 49.5H54V81H72C76.95 81 81 76.9545 81 72V9C81 4.05 76.95 0 72 0Z" fill="#40526E" />
                </svg>

            );
        case Linkedin:
            return (
                <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.05114e-06 7.10137C1.05114e-06 5.21797 0.748178 3.41171 2.07994 2.07995C3.41171 0.748186 5.21797 9.46712e-06 7.10136 9.46712e-06H77.8909C78.8243 -0.00151494 79.7487 0.181071 80.6115 0.537313C81.4741 0.893554 82.2581 1.41646 82.9184 2.07608C83.5788 2.73571 84.1026 3.5191 84.4597 4.38141C84.8169 5.24372 85.0005 6.16802 85 7.10137V77.8909C85.001 78.8244 84.8179 79.749 84.4613 80.6117C84.1046 81.4745 83.5814 82.2584 82.9214 82.9187C82.2615 83.579 81.4778 84.1027 80.6153 84.4598C79.7528 84.8169 78.8283 85.0005 77.8948 85H7.10136C6.16847 85 5.24473 84.8162 4.3829 84.4591C3.52107 84.102 2.73805 83.5785 2.07858 82.9187C1.4191 82.2589 0.896101 81.4756 0.539451 80.6135C0.182801 79.7515 -0.000506506 78.8277 1.05114e-06 77.8948V7.10137ZM33.6445 32.4082H45.1543V38.1882C46.8157 34.8655 51.0657 31.875 57.4523 31.875C69.6961 31.875 72.5977 38.4934 72.5977 50.6368V73.1309H60.207V53.4032C60.207 46.4873 58.5457 42.585 54.3266 42.585C48.4732 42.585 46.0391 46.7925 46.0391 53.4032V73.1309H33.6445V32.4082ZM12.3945 72.6016H24.7891V31.875H12.3945V72.5977V72.6016ZM26.5625 18.5918C26.5859 19.6531 26.397 20.7083 26.0071 21.6955C25.6171 22.6828 25.0339 23.5822 24.2916 24.341C23.5493 25.0998 22.6629 25.7027 21.6845 26.1143C20.706 26.526 19.6552 26.738 18.5938 26.738C17.5323 26.738 16.4815 26.526 15.503 26.1143C14.5246 25.7027 13.6382 25.0998 12.8959 24.341C12.1536 23.5822 11.5704 22.6828 11.1804 21.6955C10.7905 20.7083 10.6016 19.6531 10.625 18.5918C10.6709 16.5088 11.5306 14.5265 13.02 13.0695C14.5094 11.6125 16.5102 10.7966 18.5938 10.7966C20.6773 10.7966 22.6781 11.6125 24.1675 13.0695C25.6569 14.5265 26.5166 16.5088 26.5625 18.5918Z" fill="#40526E" />
                </svg>

            );
        case Twitter:
            return (
                <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.05114e-06 7.10137C1.05114e-06 5.21797 0.748178 3.41171 2.07994 2.07995C3.41171 0.748186 5.21797 9.46712e-06 7.10136 9.46712e-06H77.8909C78.8243 -0.00151494 79.7487 0.181071 80.6115 0.537313C81.4741 0.893554 82.2581 1.41646 82.9184 2.07608C83.5788 2.73571 84.1026 3.5191 84.4597 4.38141C84.8169 5.24372 85.0005 6.16802 85 7.10137V77.8909C85.001 78.8244 84.8179 79.749 84.4613 80.6117C84.1046 81.4745 83.5814 82.2584 82.9214 82.9187C82.2615 83.579 81.4778 84.1027 80.6153 84.4598C79.7528 84.8169 78.8283 85.0005 77.8948 85H7.10136C6.16847 85 5.24473 84.8162 4.3829 84.4591C3.52107 84.102 2.73805 83.5785 2.07858 82.9187C1.4191 82.2589 0.896101 81.4756 0.539451 80.6135C0.182801 79.7515 -0.000506506 78.8277 1.05114e-06 77.8948V7.10137ZM33.6445 32.4082H45.1543V38.1882C46.8157 34.8655 51.0657 31.875 57.4523 31.875C69.6961 31.875 72.5977 38.4934 72.5977 50.6368V73.1309H60.207V53.4032C60.207 46.4873 58.5457 42.585 54.3266 42.585C48.4732 42.585 46.0391 46.7925 46.0391 53.4032V73.1309H33.6445V32.4082ZM12.3945 72.6016H24.7891V31.875H12.3945V72.5977V72.6016ZM26.5625 18.5918C26.5859 19.6531 26.397 20.7083 26.0071 21.6955C25.6171 22.6828 25.0339 23.5822 24.2916 24.341C23.5493 25.0998 22.6629 25.7027 21.6845 26.1143C20.706 26.526 19.6552 26.738 18.5938 26.738C17.5323 26.738 16.4815 26.526 15.503 26.1143C14.5246 25.7027 13.6382 25.0998 12.8959 24.341C12.1536 23.5822 11.5704 22.6828 11.1804 21.6955C10.7905 20.7083 10.6016 19.6531 10.625 18.5918C10.6709 16.5088 11.5306 14.5265 13.02 13.0695C14.5094 11.6125 16.5102 10.7966 18.5938 10.7966C20.6773 10.7966 22.6781 11.6125 24.1675 13.0695C25.6569 14.5265 26.5166 16.5088 26.5625 18.5918Z" fill="#40526E" />
                </svg>

            );
        case WhatsApp:
            return (
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path fillRule="nonzero" d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z" />
                    </g>
                </svg>
            );
        case Email:
            return (
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#494c4e" d="M23.99 6.13V6c0-.18-.01-.35-.06-.53C23.69 4.6 22.9 4 22 4H2C1.1 4 .31 4.6.07 5.47.02 5.65 0 5.82 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2l-.01-11.87zm-2-.13L12 12 2 6h19.99zM2 18V8.33l8.97 5.38c.32.19.67.29 1.03.29s.71-.1 1.03-.29l8.96-5.38L22 18H2z" />
                </svg>
            );
        case Copy:
            return (
                <svg style={{ fill: 'transparent' }} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
            );
        case CopyLockIcon:
            return (
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.4108 28.545C23.6555 25.3134 29.3144 25.3134 32.5592 28.545L34.5839 30.5616L38.6333 26.5285L36.6086 24.5119C33.908 21.8194 30.3111 20.3333 26.485 20.3333C22.6589 20.3333 19.062 21.8194 16.3614 24.5119L10.2844 30.5616C7.60469 33.239 6.09998 36.8653 6.09998 40.6458C6.09998 44.4262 7.60469 48.0525 10.2844 50.73C11.6128 52.0549 13.1911 53.1054 14.9284 53.8211C16.6657 54.5367 18.5279 54.9034 20.408 54.9C22.2885 54.9039 24.1513 54.5375 25.8892 53.8218C27.6271 53.1062 29.2057 52.0554 30.5344 50.73L32.5592 48.7134L28.5097 44.6803L26.485 46.6969C24.8712 48.297 22.6866 49.1954 20.4094 49.1954C18.1322 49.1954 15.9477 48.297 14.3338 46.6969C12.7258 45.0903 11.8228 42.9143 11.8228 40.6458C11.8228 38.3773 12.7258 36.2013 14.3338 34.5947L20.4108 28.545Z" fill="white" />
                    <path d="M30.4656 10.2699L28.4409 12.2865L32.4903 16.3196L34.515 14.303C36.1289 12.7029 38.3134 11.8045 40.5906 11.8045C42.8678 11.8045 45.0523 12.7029 46.6662 14.303C48.2742 15.9096 49.1772 18.0856 49.1772 20.3541C49.1772 22.6227 48.2742 24.7987 46.6662 26.4053L40.5892 32.455C37.3445 35.6866 31.6856 35.6866 28.4409 32.455L26.4161 30.4384L22.3667 34.4715L24.3914 36.4881C27.092 39.1806 30.689 40.6667 34.515 40.6667C38.3411 40.6667 41.938 39.1806 44.6386 36.4881L50.7156 30.4384C53.3953 27.7609 54.9 24.1346 54.9 20.3541C54.9 16.5737 53.3953 12.9474 50.7156 10.2699C48.0281 7.59962 44.3868 6.10001 40.5906 6.10001C36.7944 6.10001 33.1531 7.59962 30.4656 10.2699Z" fill="white" />
                </svg>

            );
        case ShareOrange:
            return (
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" >
                    <path d="M15 20C14.1667 20 13.4583 19.7083 12.875 19.125C12.2917 18.5417 12 17.8333 12 17C12 16.8833 12.0083 16.7623 12.025 16.637C12.0417 16.5117 12.0667 16.3993 12.1 16.3L5.05 12.2C4.76667 12.45 4.45 12.646 4.1 12.788C3.75 12.93 3.38333 13.0007 3 13C2.16667 13 1.45833 12.7083 0.875 12.125C0.291667 11.5417 0 10.8333 0 10C0 9.16667 0.291667 8.45833 0.875 7.875C1.45833 7.29167 2.16667 7 3 7C3.38333 7 3.75 7.071 4.1 7.213C4.45 7.355 4.76667 7.55067 5.05 7.8L12.1 3.7C12.0667 3.6 12.0417 3.48767 12.025 3.363C12.0083 3.23833 12 3.11733 12 3C12 2.16667 12.2917 1.45833 12.875 0.875C13.4583 0.291667 14.1667 0 15 0C15.8333 0 16.5417 0.291667 17.125 0.875C17.7083 1.45833 18 2.16667 18 3C18 3.83333 17.7083 4.54167 17.125 5.125C16.5417 5.70833 15.8333 6 15 6C14.6167 6 14.25 5.92933 13.9 5.788C13.55 5.64667 13.2333 5.45067 12.95 5.2L5.9 9.3C5.93333 9.4 5.95833 9.51267 5.975 9.638C5.99167 9.76333 6 9.884 6 10C6 10.1167 5.99167 10.2377 5.975 10.363C5.95833 10.4883 5.93333 10.6007 5.9 10.7L12.95 14.8C13.2333 14.55 13.55 14.3543 13.9 14.213C14.25 14.0717 14.6167 14.0007 15 14C15.8333 14 16.5417 14.2917 17.125 14.875C17.7083 15.4583 18 16.1667 18 17C18 17.8333 17.7083 18.5417 17.125 19.125C16.5417 19.7083 15.8333 20 15 20Z" fill="#DA8240" />
                </svg>
            );
        case Youtube:
            return (
                <svg width="18px" height="18px" viewBox="0 0 0.36 0.36" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="red" d="M0.331 0.104a0.039 0.039 0 0 0 -0.028 -0.028C0.279 0.07 0.18 0.07 0.18 0.07s-0.099 0 -0.123 0.007c-0.013 0.004 -0.024 0.014 -0.028 0.028C0.022 0.129 0.022 0.18 0.022 0.18s0 0.051 0.006 0.076c0.004 0.013 0.014 0.024 0.028 0.028C0.081 0.29 0.18 0.29 0.18 0.29s0.099 0 0.123 -0.007a0.039 0.039 0 0 0 0.028 -0.028C0.337 0.231 0.337 0.18 0.337 0.18s0 -0.051 -0.006 -0.076z"/><path fill="#ffffff" d="m0.148 0.227 0.082 -0.047 -0.082 -0.047v0.095z"/></svg>
            );

        default:
            return (
                <svg
                    className={classes}
                    width="29"
                    height="19"
                    viewBox="0 0 29 19"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path
                            d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
                            fill="#DADDE2"
                        />
                        <path
                            d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
                            fill="#B2B6C1"
                        />
                    </g>
                </svg>
            );
    }
};

IconShare.defaultProps = {
    className: null,
    rootClassName: null,
    type: 'default',
};

IconShare.propTypes = {
    className: string,
    rootClassName: string,
    type: string,
};

export default IconShare;